import { State } from "../backends/base";

export function v1(data: any): State {
  return {
    app: {
      rootId: data.tree.rootId,
      taskId: data.app.taskId,
      view: data.app.view,
      filename: data.app.filename,
    },
    objects: data.tree.tasks,
  }
}