import { Model } from "../models/base";

export default class Transaction {
  public model: Model;

  public timestamp = (new Date()).toJSON();

  constructor(model: Model) {
    this.model = model;
  }
}