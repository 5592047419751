import { Editor } from "@tiptap/react";
import { action, makeObservable, observable } from "mobx";
import Task from "../models/task";

type EditorMap = {[key: string]: Editor};

export class EditorStore {
  @observable
  editors: EditorMap = {};

  @observable
  draggedTask: Task | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  add(id: string, value: Editor) {
    this.editors[id] = value;
  }

  @action
  remove(id: string) {
    delete this.editors[id];
  }

  @action
  setDraggedTask(task: Task) {
    this.draggedTask = task;
  }

  @action
  clearDraggedTask() {
    this.draggedTask = null;
  }

  @action
  setEditors(value: EditorMap) {
    this.editors = value;
  }

  get(id: string) {
    return this.editors[id];
  }
}

export const editorstore = new EditorStore();