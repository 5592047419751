import { Plugin, PluginKey } from 'prosemirror-state'
import { Extension } from '@tiptap/core';
import { EditorView } from 'prosemirror-view';
import { Slice } from 'prosemirror-model';
import Task, { getTask, removeContentTask, taskDescendants } from '../../models/task';
import { editorstore } from '../../stores/editorstore';

function reparentTasks(view: EditorView, slice: Slice): boolean {
  const parentId = view.dom.parentElement?.dataset.id;
  if (!parentId) {
    return false;
  }

  const parent = getTask(parentId);
  const movedTaskIds: string[] = [];
  slice.content.descendants((node) => {
    if (node.type.name === 'task') {
      movedTaskIds.push(node.attrs['data-id']);
    }
  });
  if (!movedTaskIds.length) {
    return false;
  }

  const movedTasks = movedTaskIds.map(id => getTask(id));

  const invalid = movedTasks.some((movedTask: Task) => {
    // Don't allow a task to be moved into its own subtree
    if([...taskDescendants(movedTask).map(t => t.id), movedTask.id].includes(parentId)) {
      return true;
    }

    return false;
  });

  if (invalid) {
    return true;
  }

  movedTasks.forEach((movedTask) => {
    removeContentTask(movedTask.parent!, movedTask);
    movedTask.parent = parent;
    movedTask.save()
  });

  return false;
}

const EventHandler = Extension.create({
  name: 'eventHandler',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handlePaste(view, event, slice) {
            return reparentTasks(view, slice);
          },
          handleDrop(view, event, slice) {
            return reparentTasks(view, slice);
          },
        }
      }),
    ];
  }
});

export default EventHandler;