import React from 'react';
import { CheckIcon, ClockIcon, XIcon } from '@heroicons/react/solid';
import { TaskStatus } from '../../models/constants';

export function taskStatusColor(status: TaskStatus) {
  let color = '#94a3b8';  // slate-400
  if (status === TaskStatus.IN_PROGRESS) {
    color = '#facc15';  // yellow-400
  } else if (status === TaskStatus.FINISHED) {
    color = '#4ade80';  // green-400
  }
  return color;
}

interface Args {
  status: TaskStatus;
  className?: string;
}

function StatusIcon({ status, className, ...props }: Args) {
  const color = taskStatusColor(status);
  return (
    <div
      className={`rounded-full border cursor-pointer p-0.5 ${className}`}
      style={{ borderColor: color }}
      {...props}
    >
      {status === TaskStatus.IN_PROGRESS
        ? <ClockIcon style={{ color }} />
        : status === TaskStatus.FINISHED
        ? <CheckIcon style={{ color }} />
        : status === TaskStatus.CANCELED
        ? <XIcon style={{ color }} />
        : <span></span>
      }
    </div>
  )
}

export default StatusIcon;