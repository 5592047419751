import React from 'react';
import { BubbleMenu } from '@tiptap/react';
import { Editor } from '@tiptap/core';

interface Args {
  editor: Editor;
}

function SelectionMenu({ editor }: Args) {

  const buttonClasses = 'p-2 hover:bg-slate-50 px-3'
  return (
    <BubbleMenu editor={editor}>
      <div className='relative border shadow-lg flex flex-row rounded w-full bg-white b-6'>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`${buttonClasses} ${editor.isActive('bold') ? 'is-active' : ''}`}
        >
          <p className={`m-0 p-0 font-bold ${editor.isActive('bold') ? 'text-blue-400' : ''}`}>B</p>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`${buttonClasses} ${editor.isActive('italic') ? 'is-active' : ''}`}
        >
          <p className={`m-0 p-0 italic font-serif ${editor.isActive('italic') ? 'text-blue-400' : ''}`}>I</p>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`${buttonClasses} `}
        >
          <p className={`m-0 p-0 line-through ${editor.isActive('strike') ? 'text-blue-400' : ''}`}>S</p>
        </button>
      </div>
    </BubbleMenu>
  )
}

export default SelectionMenu;