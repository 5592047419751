import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import Task, { tasksFromJSON } from '../models/task';
import { appstore } from '../stores/appstore';
import LocalForageBackend from '../backends/localforage';
import { Backend } from '../backends/base';
import * as Toast from '@radix-ui/react-toast';
import { debounce } from 'lodash';
import June from '../components/June';

async function initializeBackend(backend: Backend) {
  const { app, objects } = await backend.load();

  if (!app.rootId || !objects.length || !objects.find(o => o.id === app.rootId)) {
    const root = new Task();
    appstore.setTaskId(root.id);
    appstore.setRootId(root.id);
  } else {
    appstore.fromJSON(app);
    tasksFromJSON(objects);
  }
}

async function _refreshDataFromBackend(backend: Backend) {
  let app: any;
  let objects: any;
  try {
    const data = await backend.load();
    app = data.app;
    objects = data.objects;
  } catch (e) {
    console.error("Failed to load data from backend", e);
  }

  // Hydrate the stores and local storage with the data
  appstore.fromJSON(app);
  tasksFromJSON(objects);
}

const refreshDataFromFilesystem = debounce(_refreshDataFromBackend, 250);

function MyApp({ Component, pageProps }: AppProps) {
  const initialize = async () => {
    const localBackend = new LocalForageBackend();
    appstore.setLocalforageBackend(localBackend);
    await initializeBackend(localBackend);

    if (appstore.runtime.platform === 'desktop' && appstore.filepath && appstore.filename) {
      const fileBackend = appstore.createFileBackend(appstore.filepath, appstore.filename);
      appstore.setFileBackend(fileBackend);
      await initializeBackend(fileBackend);
    }

    appstore.setInitialized(true);
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (appstore.runtime.platform === 'desktop') {
      appstore.runtime.onUpdateStatus((e: any, code: string, ...args: any[]) => {
        console.log(code, ...args);
        if (code === 'update-downloaded') {
          appstore.setUpdateAvailable(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (appstore.initialized && appstore.fileBackend && appstore.filepath) {
      appstore.runtime.onFileChanged((e: any, filepath: string) => {
        refreshDataFromFilesystem(appstore.fileBackend!);
      });

      appstore.runtime.onFileRemoved((e: any, filepath: string) => {
        console.log('file removed', filepath);
      });

      appstore.runtime.ipcRenderer.invoke('listen', appstore.filepath);
    }
  }, [appstore.initialized]);

  if (!appstore.initialized) {
    return (
      <div className='h-screen w-full overflow-y-hidden overflow-x-auto bg-slate-100 flex items-center justify-center'>
        <p className='text-slate-400'>Loading...</p>
      </div>
    )
  }

  return (
    <Toast.Provider>
      <Component {...pageProps} />
      {process.env.NODE_ENV === 'production' && <June />}
    </Toast.Provider>
  );
}

export default observer(MyApp);
