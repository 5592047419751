import { useEditor, EditorContent } from '@tiptap/react';
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Gapcursor from '@tiptap/extension-gapcursor'
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text';
import Typography from '@tiptap/extension-typography'
import TaskExtension from './extension-task';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import Dropcursor from '@tiptap/extension-dropcursor';
import Task, { validateTaskChildren } from '../../models/task';
import CodeBlock from '@tiptap/extension-code-block';
import Code from '@tiptap/extension-code';
import { TrailingNode } from './extension-trailing-node';
import EventHandler from './extension-event-handler';
import Commands, { suggestion } from './extension-commands';
import SelectionMenu from './SelectionMenu';
import { editorstore } from '../../stores/editorstore';
import { action } from 'mobx';
import Placeholder from '@tiptap/extension-placeholder';
import { findParentNodeOfType } from 'prosemirror-utils';

interface Args {
  task: Task;
  autofocus?: boolean;
  onFocus?: () => void;
}

export const EDITOR_EXTENSIONS = [
  Bold,
  BulletList,
  Code,
  CodeBlock,
  Document,
  Dropcursor,
  EventHandler,
  Gapcursor,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TaskExtension,
  Text,
  TrailingNode,
  Typography,
  Placeholder.configure({
    placeholder: ({ node, editor }) => {
      const task = findParentNodeOfType(editor.schema.nodes.task)(editor.state.selection);
      if (task) {
        return '';
      }

      if (editor.isEmpty) {
        return 'Use CMD+Enter or [ ] to create a new task';
      }

      return 'Type "/" for commands';
    },
    includeChildren: true,
  }),
  Commands.configure({
    suggestion
  })
];

const Editor = ({
  task,
  autofocus = false,
  onFocus,
}: Args) => {
  const editor = useEditor({
    extensions: EDITOR_EXTENSIONS,
    autofocus,
    editorProps: {
      attributes: {
        class: 'prose focus:outline-none',
      },
    },
    content: {
      type: 'doc',
      content: task.content,
    },
    onFocus: () => onFocus && onFocus(),

    // TODO: this action wrapping is inelegant, but necessary to prevent errors
    // from within the content object.
    onUpdate: action(({ editor }) => {
      const data = editor.getJSON();
      if (data && data.content) {
        task.content = data.content;
        task.save();
        validateTaskChildren(task);
      }
    }),
  });

  useEffect(() => {
    if (editor) {
      editorstore.add(`content_${task.id}`, editor)
    }
    return () => {
      editorstore.remove(`content_${task.id}`);
    }
  }, [task.id, editor])

  return (
    <>
      {editor && <SelectionMenu editor={editor} />}
      <EditorContent
        editor={editor}
        data-id={task.id}
      />
    </>
  );
}

export default observer(Editor);