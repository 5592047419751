import { Backend, State } from "./base";
import { default as basestorage} from "localforage";
import { pool } from "../stores/objectstore";
import Transaction from "./transaction";
import { appstore } from "../stores/appstore";
import { CURRENT_VERSION } from "../migrations";

const localforage = basestorage.createInstance({
  name: 'flowpilot',
});

class LocalForageBackend implements Backend {
  versionKey: string = 'version';

  get stateKey(): string {
    return `v${CURRENT_VERSION}`;
  }

  async load(): Promise<State> {
    const version = await localforage.getItem(this.versionKey);
    if (version) {
      const state = await localforage.getItem(`v${version}`) as State;
      // TODO: handle migrations
      if (state) {
        return state;
      }
    }

    return {
      app: {},
      objects: [],
    }
  }

  async clear(): Promise<void> {
    await localforage.clear();
  }

  async save(): Promise<void> {
    await localforage.setItem(this.versionKey, CURRENT_VERSION);
    await localforage.setItem(this.stateKey, {
      app: appstore.toJSON(),
      objects: pool.objects.map((obj) => obj.toJSON()),
    });
  }

  async processTransaction(txn: Transaction): Promise<void> {
    await this.save();
  }
}

export default LocalForageBackend;