import { action, computed, makeObservable, observable } from "mobx";
import Task from "../models/task";
import { pool } from "./objectstore";
import FileHandleBackend from "../backends/filehandle";
import LocalForageBackend from "../backends/localforage";
import FileSystemBackend from "../backends/filesystem";

declare global {
  interface Window {
    runtime?: any;
  }
}

const CURRENT_VERSION = 1;

export enum View {
  TASK,
  COLUMN,
  CANVAS,
}

interface Runtime {
  platform: 'desktop' | 'web';
  ipcRenderer?: any | undefined;
  onUpdateStatus: (...args: any[]) => void;
  onFileChanged: (...args: any[]) => void;
  onFileRemoved: (...args: any[]) => void;
}

export class AppStore {
  key = 'app';

  runtime: Runtime = (typeof window !== 'undefined' && window.runtime) ? window.runtime : { platform: 'web' };

  @observable
  version: number = CURRENT_VERSION;

  @observable
  initialized: boolean = false;

  @observable
  rootId: string | null = null;

  @observable
  taskId: string | null = null;

  @observable
  view: View = View.COLUMN;

  @observable
  filename: string | null = null;

  @observable
  filepath: string | null = null;

  @observable
  localforageBackend: LocalForageBackend | null = null;

  @observable
  fileBackend: FileHandleBackend | FileSystemBackend | null = null;

  @observable
  updateAvailable: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @computed
  get task(): Task {
    let task: Task | undefined;
    if (this.taskId) {
      task = pool.get(this.taskId) as Task | undefined;
    }
    if (!task) {
      task = pool.get(this.rootId!) as Task;
    }
    return task!;
  }

  @action
  setInitialized(initialized: boolean) {
    this.initialized = initialized;
  }

  @action
  setLocalforageBackend(backend: LocalForageBackend) {
    this.localforageBackend = backend;
  }

  @action
  setFileBackend(backend: FileHandleBackend | FileSystemBackend | null) {
    this.fileBackend = backend;
  }

  @action
  setUpdateAvailable(updateAvailable: boolean) {
    this.updateAvailable = updateAvailable;
  }

  @action
  setRootId(rootId: string) {
    this.rootId = rootId;

    if (this.localforageBackend) {
      this.localforageBackend.save();
    }
    if (this.fileBackend) {
      this.fileBackend.save();
    }
  }

  @action
  setTaskId(taskId: string) {
    this.taskId = taskId;

    if (this.localforageBackend) {
      this.localforageBackend.save();
    }
    if (this.fileBackend) {
      this.fileBackend.save();
    }
  }

  @action
  setFilename(filename: string | null) {
    this.filename = filename;

    if (this.localforageBackend) {
      this.localforageBackend.save();
    }
    if (this.fileBackend) {
      this.fileBackend.save();
    }
  }

  @action
  setFilepath(filepath: string | null) {
    this.filepath = filepath;

    if (this.localforageBackend) {
      this.localforageBackend.save();
    }
    if (this.fileBackend) {
      this.fileBackend.save();
    }
  }

  @action
  setView(view: View) {
    this.view = view;

    if (this.localforageBackend) {
      this.localforageBackend.save();
    }
    if (this.fileBackend) {
      this.fileBackend.save();
    }
  }

  @action
  fromJSON({ rootId, taskId, view, filename, filepath }: any) {
    this.rootId = rootId;
    this.taskId = taskId;
    this.view = view;
    this.filename = filename;
    this.filepath = filepath;
  }

  toJSON() {
    return {
      rootId: this.rootId,
      taskId: this.taskId,
      view: this.view,
      filename: this.filename,
      filepath: this.filepath,
    };
  }

  @action
  clear() {
    this.rootId = null;
    this.taskId = null;
    this.view = View.COLUMN;
    this.filename = null;
    this.filepath = null;
    this.localforageBackend = null;
    this.fileBackend = null;
  }

  @action
  refreshFileProperties() {
    if (this.fileBackend) {
      this.filename = this.fileBackend.filename;

      if (this.runtime.platform === 'desktop') {
        this.filepath = (this.fileBackend as FileSystemBackend).filepath;
      }

      this.fileBackend.save();
    }

    if (this.localforageBackend) {
      this.localforageBackend.save();
    }
  }

  createFileBackend(...args: any[]) {
    if (this.runtime.platform === 'desktop') {
      return new FileSystemBackend(this.runtime.ipcRenderer, ...args);
    } else {
      return new FileHandleBackend(...args);
    }
  }
}

export const appstore = new AppStore();