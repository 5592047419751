import { generateHTML, generateJSON, generateText, JSONContent } from "@tiptap/core";
import { action, computed, toJS } from "mobx";
import { v4 } from "uuid";
import { EDITOR_EXTENSIONS } from "../components/TaskContentEditor";
import { editorstore } from "../stores/editorstore";
import { pool } from "../stores/objectstore";
import { PersistedModel, Model, Property, ManyToOne, OneToMany } from "./base";
import { TaskStatus } from "./constants";

export const DEFAULT_CONTENT = [
  {
    type: 'paragraph',
    content:  []
  }
];

@PersistedModel("Task")
class Task extends Model {
  @Property()
  public id: string = v4();

  @Property()
  public title: string = "";

  @Property()
  public status: TaskStatus = TaskStatus.TODO;

  @Property()
  public content: JSONContent[] = DEFAULT_CONTENT;

  @Property()
  public deleted: boolean = false;

  @ManyToOne()
  public parent: Task | undefined;

  @OneToMany("parent")
  public children: Task[] = [];

  constructor(...args: any[]) {
    super(...args);

    // We have to establish defaults because these are proxied by the @Property decorator.
    this.title = '';
    this.status = TaskStatus.TODO;
    this.content = DEFAULT_CONTENT;
    this.deleted = false;
  }

  @computed
  get isPruned(): boolean {
    return this.status === TaskStatus.FINISHED || this.status === TaskStatus.CANCELED || this.deleted;
  }
}

export function getTask(id: string): Task {
  return pool.get(id) as Task;
}

export function tasksFromJSON(data: any) {
  const tasks: Task[] = data.map((taskData: any) => {
    let model = getTask(taskData.id);

    if (!model) {
      model = new Task(taskData.id);
    }

    if (model.title !== taskData.title) {
      model.title = taskData.title;
    }

    model.content = taskData.content;
    model.status = taskData.status;
    model.deleted = taskData.deleted;
    return model;
  });

  tasks.forEach((task: Task) => {
    const parentId = data.find((item: any) => item.id === task.id).parentId;
    if (parentId) {
      task.parent = tasks.find((t) => t.id === parentId);
    }

    let editor = editorstore.get(`content_${task.id}`);
    if (editor && JSON.stringify(editor.getJSON()) !== JSON.stringify(taskDoc(task))) {
      editor.commands.setContent(task.content);
    }
  });
}

export function taskStatusName(status: TaskStatus): string {
  if (status === TaskStatus.TODO) {
    return "Todo";
  } else if (status === TaskStatus.IN_PROGRESS) {
    return "In Progress";
  } else if (status === TaskStatus.FINISHED) {
    return "Done";
  } else if (status === TaskStatus.CANCELED) {
    return "Canceled";
  }
  throw Error("Unknown task status");
}

export function taskAncestors(task: Task): Task[] {
  const ancestors: Task[] = [];
  let parent = task.parent;
  while (parent) {
    ancestors.push(parent);
    parent = parent.parent;
  }
  return ancestors;
}

export function taskDescendants(task: Task): Task[] {
  let descendants: Task[] = [];
  for (let child of task.children) {
    if (!child.deleted) {
      descendants = descendants.concat([child, ...taskDescendants(child)]);
    }
  }
  return descendants;
}

export function taskDoc(task: Task): JSONContent {
  return {
    type: 'doc',
    content: task.content
  };
}

export function taskContentHtml(task: Task) {
  return generateHTML(taskDoc(task), EDITOR_EXTENSIONS);
}

export function taskContentText(task: Task) {
  return generateText(taskDoc(task), EDITOR_EXTENSIONS);
}

function _addContentTask(task: Task, child: Task) {
  let html = `<task data-id="${child.id}"><p>${child.title}<\/p><\/task>`;
  const taskHtml = taskContentHtml(task)
  if (taskHtml !== '<p></p>') {
    html = taskHtml + html
  }
  task.content = generateJSON(html, EDITOR_EXTENSIONS).content;
  task.save();

  const editor = editorstore.get(`content_${task.id}`);
  if (editor) {
    editor.commands.setContent(task.content);
  }
}
export const addContentTask = action(_addContentTask);

function _updateContentTaskTitle(task: Task,childId: string, title: string) {
  const pattern = new RegExp(`<task data-id="${childId}"><p>.*?<\/p><\/task>`);
  const replacement = `<task data-id="${childId}"><p>${title}<\/p><\/task>`;
  const html = taskContentHtml(task).replace(pattern, replacement);
  task.content = generateJSON(html, EDITOR_EXTENSIONS).content;
  task.save();

  const editor = editorstore.get(`content_${task.id}`);
  if (editor) {
    editor.commands.setContent(task.content);
  }
}
export const updateContentTaskTitle = action(_updateContentTaskTitle);

function _removeContentTask(task: Task, child: Task) {
  const pattern = new RegExp(`<task data-id="${child.id}"><p>.*?<\/p><\/task>`);
  const html = taskContentHtml(task).replace(pattern, '');
  task.content = generateJSON(html, EDITOR_EXTENSIONS).content;
  task.save();

  const editor = editorstore.get(`content_${task.id}`);
  if (editor) {
    editor.commands.setContent(task.content);
  }
}
export const removeContentTask = action(_removeContentTask);

export function validateTaskChildren(task: Task) {
  const html = taskContentHtml(task);
  task.children.forEach((child) => {
    if (html.includes(child.id) && child.deleted) {
      console.log(`task: restoring ${child.id}`);
      child.deleted = false;
      child.save();
    } else if (!html.includes(child.id) && !child.deleted) {
      console.log(`task: soft deleting ${child.id}`);
      child.deleted = true;
      child.save();
    }
  });
}

export function searchTasks(query: string): Task[] {
  return pool.objects.filter((obj) => {
    if (obj.type === 'Task') {
      const task = obj as Task;
      return task.title.toLowerCase().includes(query.toLowerCase()) && !task.isPruned && !taskAncestors(task).some(t => t.isPruned);
    }
    return false;
  }) as Task[];
}

export default Task;