import { Backend, State } from "./base";
import { fileOpen, fileSave, FileSystemHandle } from 'browser-fs-access';
import { pool } from "../stores/objectstore";
import { appstore } from "../stores/appstore";
import Transaction from "./transaction";
import { CURRENT_VERSION, migrate } from "../migrations";
import { debounce } from "lodash";

class FileHandleBackend implements Backend {
  public filename: string | null = null;
  public fileHandle: FileSystemHandle | null = null;

  constructor(filename?: string | null) {
    this.filename = filename || null;
  }

  async load(): Promise<State> {
    const blob = await fileOpen({
      id: 'flowpilot',
      extensions: ['.json'],
      description: 'Data file',
    });

    const data = JSON.parse(await blob.text());
    const state = migrate(data);

    if (blob.handle) {
      this.fileHandle = (blob as any).handle;
      this.filename = (blob as any).name;
    }

    return state;
  }

  async save(): Promise<void> {
    const data = {
      version: CURRENT_VERSION,
      [`v${CURRENT_VERSION}`]: {
        app: appstore.toJSON(),
        objects: pool.objects.map((obj) => obj.toJSON()),
      }
    };

    const json = JSON.stringify(data);
    const blob = new Blob([json], {type : "application/json"});

    try {
      const handle = await fileSave(blob, {
        id: 'flowpilot',
        fileName: this.filename || 'data.flowpilot.json',
        extensions: ['.flowpilot.json'],
        description: 'Data file',
      }, this.fileHandle, true);

      if (handle) {
        this.fileHandle = handle;
        this.filename = handle.name;
      }
    } catch (e: any) {
      this.fileHandle = null;
      throw e;
    }
  }

  batchSave = debounce(this.save, 3000);

  async processTransaction(txn: Transaction): Promise<void> {
    this.batchSave();
  }

  async clear(): Promise<void> {
    this.fileHandle = null;
  }
}

export default FileHandleBackend;