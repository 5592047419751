import { Editor, Extension, Range } from '@tiptap/core'
import { ReactRenderer } from '@tiptap/react'
import Suggestion from '@tiptap/suggestion'
import { useCallback, useEffect, useState } from 'react'
import tippy from 'tippy.js'
import { findParentNodeOfType } from 'prosemirror-utils';

export default Extension.create({
  name: 'slashCommands',

  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }: any) => {
          props.command({ editor, range })
        },
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
});

interface CommandItem {
  title: string;
  command: () => void;
}

interface CommandsListArgs {
  items: CommandItem[];
  command: any;
}

const CommandsList = ({ items, command }: CommandsListArgs) => {
  const [selected, setSelected] = useState(0);

  function selectItem(item: CommandItem) {
    command(item);
  }

  const handler = useCallback((e) => {
    if (e.key === 'ArrowUp') {
      setSelected(((selected + items.length) - 1) % items.length);
      return true;
    } else if (e.key === 'ArrowDown') {
      setSelected((selected + 1) % items.length);
      return true;
    } else if (e.key === 'Enter') {
      selectItem(items[selected]);
      return true;
    }
    return false;
  }, [selected, setSelected, selectItem]);

  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [selected, setSelected]);

  return (
    <div className='relative border shadow-lg flex flex-col rounded'>
      {items.map((i, idx) => (
        <button
          key={i.title}
          className={`${selected === idx ? 'bg-slate-200' : 'bg-white'} w-full block hover:bg-slate-200 px-4 py-2 text-left`}
          onClick={() => selectItem(i)}
        >
          {i.title}
        </button>
      ))}
    </div>
  );
}

interface CommandArgs {
  editor: Editor;
  range: Range;
}

export const suggestion = {
  items: ({ query }: { query: string }) => {
    return [
      {
        title: 'Task',
        command: ({ editor, range }: CommandArgs) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleTask()
            .run();
        },
      },
      {
        title: 'Heading 1',
        command: ({ editor, range }: CommandArgs) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 1 })
            .run();
        },
      },
      {
        title: 'Heading 2',
        command: ({ editor, range }: CommandArgs) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 2 })
            .run();
        },
      },
      {
        title: 'Heading 3',
        command: ({ editor, range }: CommandArgs) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 2 })
            .run();
        },
      },
      {
        title: 'Bulleted List',
        command: ({ editor, range }: CommandArgs) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleBulletList()
            .run();
        },
      },
      {
        title: 'Numbered List',
        command: ({ editor, range }: CommandArgs) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleOrderedList()
            .run();
        },
      },
    ].filter(item => item.title.toLowerCase().startsWith(query.toLowerCase())).slice(0, 10)
  },

  render: () => {
    let component: ReactRenderer;
    let popup: any;

    return {
      onStart: (props: any) => {
        const task = findParentNodeOfType(props.editor.schema.nodes.task)(props.editor.state.selection);
        if (task) {
          return;
        }

        component = new ReactRenderer(CommandsList, {
          props,
          editor: props.editor,
        });
        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props: any) {
        component.updateProps(props)
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide()
          return true
        } else if (
          props.event.key === 'Enter'
          || props.event.key === 'ArrowUp'
          || props.event.key === 'ArrowDown'
        ) {
          return true;
        }
        return (component as any).ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    };
  },
}