import React, { useCallback, useEffect, useState } from 'react';
import { TaskStatus } from '../../models/constants';
import { taskStatusName } from '../../models/task';
import StatusIcon from './StatusIcon';

interface Args {
  onSelect: (status: TaskStatus) => void;
}

function StatusOptions({ onSelect }: Args) {
  const [selected, setSelected] = useState(0);
  const options = Object.keys(TaskStatus).map(v => parseInt(v)).filter(v => !Number.isNaN(v));

  function selectResult(result: TaskStatus) {
    onSelect(result);
  }

  const handler = useCallback((e) => {
    if (e.key === 'ArrowUp') {
      setSelected(((selected + options.length) - 1) % options.length);
      return true;
    } else if (e.key === 'ArrowDown') {
      setSelected((selected + 1) % options.length);
      return true;
    } else if (e.key === 'Enter') {
      selectResult(options[selected]);
      return true;
    }
    return false;
  }, [selected, setSelected, selectResult]);

  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [handler]);

  return (
    <div className='relative border shadow-lg flex flex-col rounded w-full max-h-72 w-64 overflow-y-auto overflow-x-hidden'>
      {options.map((i, idx) => (
        <button
          key={i}
          className={`${selected === idx ? 'bg-slate-50' : 'bg-white'} w-full block hover:bg-slate-50 px-4 py-2 text-left flex flex-row items-center`}
          onClick={() => selectResult(i)}
        >
          <StatusIcon status={i} className='h-4 w-4 mr-4' />
          <p className='prose-sm m-0'>{taskStatusName(i)}</p>
        </button>
      ))}
    </div>
  );
}

export default StatusOptions