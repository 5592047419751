import { observable, makeObservable, computed, action, toJS } from "mobx";
import { v4 } from "uuid";
import "reflect-metadata";
import { pool } from "../stores/objectstore";

type Constructor = { new (...args: any[]): any };

export class Model {
  [index: string]: any;

  public type!: string;

  public id: string = v4();

  constructor(id?: string) {
    if (id) {
      this.id = id;
    }
    pool.add(this as any);
  }

  save() {
    pool.save(this as any);
  }
}

export function PersistedModel(key: string) {
  return function _PersistedModel<T extends Constructor>(constr: T){
    // Make the new class observable
    class __PersistedModel extends constr {
      constructor(...args: any[]) {
        super(...args)
        makeObservable(this);
        this.type = key;
      }

      toJSON() {
        const val: { [key: string]: any } = {
          type: this.type,
        }

        Reflect.getMetadataKeys(this).forEach((key: string) => {
          const property = Reflect.getMetadata(key, this);
          val[property] = toJS(this[key.replace('prop:', '')]);
        });

        return val;
      }

    }
    return __PersistedModel;
  }
}

export function Property() {
  return (target: any, propertyKey: string) => {
    const key = `_${propertyKey}`

    Reflect.defineMetadata(`prop:${key}`, propertyKey, target);

    Object.defineProperty(target, key, {
      configurable: true,
      enumerable: true,
      writable: true,
    });

    observable(target, key);

    function getter(this: Model) {
      return this[key as keyof Model];
    }

    function setter(this: Model, value: any) {
      this[key as keyof Model] = value;
    }

    Object.defineProperty(target, propertyKey, {
      configurable: true,
      enumerable: true,
      get: getter,
      set: action(setter)
    });

    computed(target, propertyKey);
  }
}

export function ManyToOne() {
  return (target: any, propertyKey: string) => {
    const idKey = `${propertyKey}Id`

    Reflect.defineMetadata(`prop:${idKey}`, idKey, target);

    Object.defineProperty(target, idKey, {
      configurable: true,
      enumerable: true,
      writable: true,
    });

    observable(target, idKey);

    function getter(this: Model) {
      return pool.objects.find(model => model.id === this[idKey as keyof Model]);
    }

    function setter(this: Model, value: Model) {
      this[idKey] = value.id;
    }

    Object.defineProperty(target, propertyKey, {
      configurable: true,
      enumerable: true,
      get: getter,
      set: action(setter),
    });

    computed(target, propertyKey);
  }
}

export function OneToMany(reverseName: string) {
  return (target: any, propertyKey: string) => {
    const idKey = `${reverseName}Id`

    function getter(this: Model) {
      return pool.objects.filter(model => model[idKey as keyof Model] === this.id);
    }

    function setter(this: Model, value: Model[]) {
      value.forEach(model => model[idKey] = this.id);
    }

    Object.defineProperty(target, propertyKey, {
      configurable: true,
      enumerable: true,
      get: getter,
      set: action(setter),
    });

    computed(target, propertyKey);
  }
}