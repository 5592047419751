
import { Backend, State } from "./base";
import { pool } from "../stores/objectstore";
import { appstore } from "../stores/appstore";
import Transaction from "./transaction";
import { CURRENT_VERSION, migrate } from "../migrations";
import { debounce } from "lodash";

class FileSystemBackend implements Backend {
  public filename: string | null = null;
  public filepath: string | null = null;
  public fs: any;  // TODO: refactor into a proper class

  constructor(fs: any, filepath?: string | null, filename?: string | null) {
    this.fs = fs;
    this.filepath = filepath || null;
    this.filename = filename || null;
  }

  async load(): Promise<State> {
    let filepath: string;
    let filename: string;
    let content: string;

    if (!this.filepath || !this.filename) {
      const response = await this.fs.invoke('selectOpenFile', {});
      filepath = response.filePath;
      filename = response.fileName;
      content = response.content;
    } else {
      filepath = this.filepath;
      filename = this.filename;
      content = await this.fs.invoke('readFile', this.filepath);
    }
    const data = JSON.parse(content);
    const state = migrate(data);

    this.filepath = filepath;
    this.filename = filename;
    return state;
  }

  async save(): Promise<void> {
    if (!this.filepath) {
      const { filePath, fileName } = await this.fs.invoke('selectSaveFile', {
        defaultPath: this.filename || 'data.flowpilot.json',
      });

      this.filepath = filePath;
      this.filename = fileName;
    }

    const data = {
      version: CURRENT_VERSION,
      [`v${CURRENT_VERSION}`]: {
        app: appstore.toJSON(),
        objects: pool.objects.map((obj) => obj.toJSON()),
      }
    };

    const json = JSON.stringify(data);
    await this.fs.invoke('writeFile', this.filepath, json);
  }

  batchSave = debounce(this.save, 3000);

  async processTransaction(txn: Transaction): Promise<void> {
    this.batchSave();
  }

  async clear(): Promise<void> {
    this.filepath = null;
    this.filename = null;
  }
}

export default FileSystemBackend;