import { ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import TaskStatusInput from '../TaskStatusInput';
import Task, { addContentTask, getTask, removeContentTask, taskAncestors, taskContentText } from '../../models/task';
import Link from 'next/link';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { observer } from 'mobx-react-lite';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { editorstore } from '../../stores/editorstore';
import { appstore } from '../../stores/appstore';

declare module '@tiptap/core' {
  interface Commands {
    task: {
      toggleTask: () => any,
    }
  }
}

const DragHandle = () => {
  return (
    <div
      className='cursor-grab w-4'
      draggable={true}
      contentEditable={false}
      data-drag-handle
    >⠿</div>
  )
}

const isTarget = (e: Event, id: string) => {
  const path = e.composedPath();
  for (let node of path) {
    if ((node as HTMLElement).dataset && (node as HTMLElement).dataset.id === id) {
      return true;
    }
  }
  return false;
}

const TaskComponent = ({ node, editor, updateAttributes }: any) => {
  const el = useRef<HTMLDivElement>(null);
  const [task, setTask] = useState<Task | null>(null);
  const [subtasks, setSubtasks] = useState<Task[]>([]);
  const [draggedOver, setDraggedOver] = useState(false);
  const parentTask = getTask(editor.view.dom.parentElement.dataset.id);

  useEffect(() => {
    let t: Task;
    if (node.attrs['data-id']) {
      t = getTask(node.attrs['data-id']);
    } else {
      t = new Task();
      t.parent = parentTask;
      t.save();
      updateAttributes({ 'data-id': t.id });
    }

    if (t) {
      setTask(t);
      setSubtasks(t.children);
    }
  }, [node.attrs]);

  useEffect(() => {
    const title = node.textContent
    if (task && node.attrs['data-id'] === task.id && task.title !== title) {
      task.title = title;
      task.save();
    }
  }, [node.textContent])

  const dragstart = useCallback((e: DragEvent) => {
    if (task) {
      editorstore.setDraggedTask(task);
    }
  }, [task]);

  const drop = useCallback((e: DragEvent) => {
    e.preventDefault();
    if (task && editorstore.draggedTask && isTarget(e, task.id) && editorstore.draggedTask.id !== task.id) {
      // First update the task and editor where the task is coming from
      const fromTask = editorstore.draggedTask.parent!;
      removeContentTask(fromTask, editorstore.draggedTask);
      if (editorstore.editors[fromTask.id]) {
        editorstore.editors[fromTask.id].commands.setContent(fromTask.content)
      }

      // Add the task to the new parent (this current task) and the new parent's editor
      addContentTask(task, editorstore.draggedTask);
      if (editorstore.editors[task.id]) {
        editorstore.editors[task.id].commands.setContent(task.content);
      }

      // Update the parent pointer on the dragged task to the new parent
      editorstore.draggedTask.parent = task;
      setSubtasks(task.children)
    }

    setDraggedOver(false);
  }, [editorstore.draggedTask, task, el.current]);

  const dragover = useCallback((e) => {
    setDraggedOver(true);
  }, []);

  const dragleave = useCallback((e) => {
    setDraggedOver(false);
  }, []);

  const dragend = useCallback((e: DragEvent) => {
    editorstore.clearDraggedTask();
  }, []);

  useEffect(() => {
    if (el.current) {
      el.current.addEventListener('dragstart', dragstart);
      el.current.addEventListener('drop', drop);
      el.current.addEventListener('dragover', dragover);
      el.current.addEventListener('dragleave', dragleave);

      return () => {
        if (el.current) {
          el.current!.removeEventListener('dragstart', dragstart);
          el.current!.removeEventListener('drop', drop);
          el.current!.removeEventListener('dragover', dragover);
          el.current!.removeEventListener('dragleave', dragleave);
        }
      };
    }
  }, [drop, dragstart, dragend, dragleave, dragover, el]);

  const incompleteSubstasks = subtasks.filter(t => t.isPruned);
  const isAncestorOfCurrent = appstore.task && taskAncestors(appstore.task).map(t => t.id).concat(appstore.task.id).includes(node.attrs['data-id']);
  return (
    <NodeViewWrapper
      className={`border rounded flex flex-1 my-1 p-0 select-none ${draggedOver ? 'border-blue-400' : ''} ${isAncestorOfCurrent ? 'bg-slate-50' : ''}`}
      data-type='task'
      ref={el}
      {...node.attrs}
    >
      <div className={`flex flex-row justify-between w-full px-2 items-center ${(task && task.isPruned) ? 'py-0' : 'py-1 '}`}>
        {task &&
          <div className='flex flex-row items-center' contentEditable={false}>
            <a
              onClick={(e) => { appstore.setTaskId(task.id) }}
              className='flex flex-row items-center cursor-pointer px-2'
              contentEditable={false}
            >
              <ArrowLeftIcon className='text-slate-400 w-4 h-4' />
            </a>
            <TaskStatusInput task={task} className='task-status-selector w-4 h-4 mr-2' />
          </div>
        }
        <NodeViewContent
          className={`flex-1 task-title cursor-text ${(task && task.isPruned) ? 'line-through text-slate-300 prose-sm' : 'my-1'}`}
        />
        <div className='flex flex-row items-center' contentEditable={false}>
          {(task && !task.isPruned && subtasks.length && incompleteSubstasks.length !== subtasks.length)
            ? <p className='pr-2 text-slate-400 prose-sm'>{`${incompleteSubstasks.length}/${subtasks.length}`}</p>
            : ''
          }
          <DragHandle />
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export const inputRegex = /^\s*(\[([ |x])\])\s$/;

const Extension = Node.create({
  name: 'task',

  group: 'block',

  draggable: true,

  allowGapCursor: true,

  content() {
    return 'paragraph';
  },

  addAttributes() {
    return {
      'data-id': {
        default: null,
      }
    }
  },

  // Note: changing the below requires updating the regular expressions in task.ts
  renderHTML({ HTMLAttributes }) {
    return [
      'task',
      mergeAttributes(HTMLAttributes),
      0
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'task',
      }
    ];
  },

  addCommands() {
    return {
      toggleTask: () => ({ commands, editor }: any) => {
        const { state } = editor
        const { selection } = state
        const { $from } = selection;

        let taskNode: ProsemirrorNode | null = null;
        for (let i = 0; i < $from.depth; i++) {
          if ($from.node(i).type === this.type) {
            taskNode = $from.node(i);
            break;
          }
        }

        if (taskNode) {
          const task = getTask(taskNode?.attrs['data-id']);
          if (task && (task.title || taskContentText(task))) {
            return false;
          }
        }
        return commands.toggleWrap(this.name, this.options.itemTypeName)
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Enter': ({ editor }) => {
        return editor.commands.toggleTask();
      },
      Enter: ({ editor }) => {
        const { state } = editor
        const { selection } = state
        const { $from } = selection;

        const chain = editor.chain();

        let taskNode = null;
        for (let i = 0; i < $from.depth; i++) {
          if ($from.node(i).type === this.type) {
            taskNode = $from.node(i);
            break;
          }
        }

        if (!taskNode) {
          return false;
        }

        return chain.selectTextblockEnd().insertContent({type: 'paragraph'}).run();
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(observer(TaskComponent));
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ]
  }
});

export default Extension;