import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Task from '../../models/task';
import Tippy from '@tippyjs/react';
import StatusOptions from './StatusOptions';
import StatusIcon from './StatusIcon';
import { TaskStatus } from '../../models/constants';

interface Args {
    task: Task,
    className?: string
}

function TaskStatusInput({ task, className, ...props }: Args) {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <Tippy
      render={() => {
        if (!!showMenu) {
          return (
            <StatusOptions
              onSelect={(status: TaskStatus) => {
                task.status = status;
                task.save()
                setShowMenu(false);
              }}
            />
          );
        }
        return <></>;
      }}
      visible={showMenu}
      onClickOutside={() => setShowMenu(false)}
      interactive
      placement='bottom-start'
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          setShowMenu(!showMenu);
        }}
      >
        <StatusIcon
          status={task.status}
          className={className}
          {...props}
        />
      </div>
    </Tippy>
  );
}

export default observer(TaskStatusInput);