export enum TaskStatus {
  TODO,
  IN_PROGRESS,
  FINISHED,
  CANCELED,
};

export enum View {
  TASK,
  COLUMN,
  CANVAS,
};