import { State } from "../backends/base";
import { v1 } from "./v1";

export const CURRENT_VERSION = 1;

export function migrate(data: any): State {
  let state;
  if (!data.version || !data[`v${data.version}`]) {
    if (data.app && data.tree) {
      state = v1(data);
    } else {
      throw new Error('Invalid data file');
    }
  } else {
    state = data[`v${data.version}`];
  }
  return state;
}