import { action, makeObservable, observable } from "mobx";
import { Model } from "../models/base";
import Transaction from "../backends/transaction";
import { appstore } from "./appstore";

export class ObjectStore {
  @observable.deep
  objects: Model[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  add(model: Model) {
    this.objects = this.objects.filter((obj) => obj.id !== model.id);
    this.objects.push(model);
  }

  @action
  clear() {
    this.objects = [];
  }

  @action
  remove(model: Model) {
    this.objects.splice(this.objects.indexOf(model), 1);
  }

  get(id: string) {
    return this.objects.find((model) => model.id === id);
  }

  save(model: Model) {
    const transaction = new Transaction(model);

    if (appstore.localforageBackend) {
      appstore.localforageBackend.processTransaction(transaction);
    }

    if (appstore.fileBackend) {
      appstore.fileBackend.processTransaction(transaction);
    }
  }
}

export const pool = new ObjectStore();
